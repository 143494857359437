<footer class="bg-white dark:bg-gray-900">
  <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
    <div class="sm:flex sm:justify-between">
      <div class="mb-6 sm:mb-0">
        <a href="/" class="flex items-center justify-center">
          <app-logo height="60px" color="light"></app-logo>
        </a>
      </div>
      <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
        <div class="text-center sm:text-start">
          <h2 class="mb-6 text-base font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
          <ul class="text-gray-500 dark:text-gray-400 font-medium">
            <li class="mb-4">
              <!--<a [href]="patreonUrl" class="hover:underline" target="_blank">Patreon</a>-->
            </li>
            <li>
              <a [href]="discordUrl" class="hover:underline" target="_blank">Discord</a>
            </li>
          </ul>
        </div>
        <div class="text-center sm:text-start">
          <h2 class="mb-6 text-base font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
          <ul class="text-gray-500 dark:text-gray-400 font-medium">
            <li class="mb-4">
              <a [routerLink]="RouteLocations.Privacy.absoluteUrl" class="hover:underline">Privacy Policy</a>
            </li>
            <li>
              <a [routerLink]="RouteLocations.Terms.absoluteUrl" class="hover:underline">Terms &amp; Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <div class="flex flex-col sm:flex-row-reverse sm:items-center sm:justify-between">
      <div class="flex space-x-5 justify-center">
        <a [href]="discordUrl" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
               viewBox="0 0 21 16">
            <path
              d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
          </svg>
          <span class="sr-only">Discord community</span>
        </a>
      </div>
      <div class="text-base text-gray-500 text-center dark:text-gray-400 mt-5 sm:mt-0">
        © {{year}} <a href="/" class="hover:underline">Gomoque™</a>.
        All Rights Reserved.
      </div>
    </div>
  </div>
</footer>
