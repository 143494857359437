import { GamePairingId } from './game-pairings.dto';
import { GameId } from '../game/model/game-common.model';
import { GameTurnActivity } from '../game/model/game-turn.model';
import { ServerSocketAuthToken } from './auth.dto';

export const WEBSOCKET_MESSAGE = 'message';

export enum WebsocketMessageType {
  AuthConnectRequest = 'auth-connect-request',
  AuthConnectResponse = 'auth-connect-response',
  GameStarted = 'game-started',
  GameActivity = 'game-activity',
}

export interface WebsocketMessageDTO<T> {
  type: WebsocketMessageType;

  data: T;
}

export interface WSGameStartedDTO extends WebsocketMessageDTO<{
  gameId: GameId;

  pairingIds: GamePairingId[];
}> {
  type: WebsocketMessageType.GameStarted;
}

export interface WSGameActivityDTO extends WebsocketMessageDTO<{
  gameId: GameId;

  activity: GameTurnActivity;
}> {
  type: WebsocketMessageType.GameActivity;
}

export interface WSAuthConnectRequestDTO extends WebsocketMessageDTO<{
  token: ServerSocketAuthToken;
}> {
  type: WebsocketMessageType.AuthConnectRequest;
}

export interface WSAuthConnectResponseDTO extends WebsocketMessageDTO<{
  successful: boolean;
}> {
  type: WebsocketMessageType.AuthConnectResponse;
}