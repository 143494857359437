import { Injectable } from '@angular/core';
import { ISODateString } from '../../../../../common/dto/date.dto';

@Injectable()
export class DateProvider {
  public now() {
    return new Date();
  }

  public nowISO(): ISODateString {
    return <ISODateString>this.now().toISOString();
  }
}
