import { Inject, Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { Store } from '@ngxs/store';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT, WINDOW } from './injection-tokens';
import { lastValueFrom } from 'rxjs';
import { SetSessionAction } from '../../app.state';
import { SERVER_URL_PREFIX, ServerUrls } from '../../../../../common/dto/server-urls';
import { RouteNavigationService } from '../routing/route-navigation.service';
import { UserSession } from '../../../../../common/dto/users.dto';
import { AuthenticationType, ServerSocketAuthTokenDTO } from '../../../../../common/dto/auth.dto';

export const API_TOKEN_STORAGE_KEY = 'ogma-assistant-token';

@Injectable()
export class AuthenticationService {
  constructor(
    private _store: Store,
    private _http: CoolHttp,
    private _localStorage: CoolLocalStorage,
    private _routeNavigationService: RouteNavigationService,
    @Inject(ENVIRONMENT) private _environment: Environment,
    @Inject(WINDOW) private _window: Window,
  ) {}

  public async initializeAsync() {
    await this.refreshUserSessionAsync();
  }

  public async loginAsync(authType: AuthenticationType) {
    switch (authType) {
      case AuthenticationType.Google:
        await this.loginWithGoogleAsync();
        break;
      case AuthenticationType.Apple:
        await this.loginWithAppleAsync();
        break;
      default:
        throw new Error(`Unknown auth type: ${ authType }`);
    }
  }

  public async loginWithAppleAsync() {
    this._window.location.href = `${ this._environment.serverUrl }/${ SERVER_URL_PREFIX }/${ ServerUrls.Authentications.children.Apple.children.Get.absoluteUrlWithoutLeading }`;
  }

  public async loginWithGoogleAsync() {
    this._window.location.href = `${ this._environment.serverUrl }/${ SERVER_URL_PREFIX }/${ ServerUrls.Authentications.children.Google.children.Get.absoluteUrlWithoutLeading }`;
  }

  public async refreshUserSessionAsync(): Promise<UserSession | undefined> {
    let response: any = undefined;

    try {
      response = await this._http.postAsync<UserSession>(ServerUrls.Authentications.children.Session.children.CreateNew.absoluteUrl);
    } catch {}

    await lastValueFrom(this._store.dispatch(new SetSessionAction(response)));

    return response;
  }

  public async getServerSocketAuthTokenAsync(): Promise<ServerSocketAuthTokenDTO> {
    return await this._http.postAsync(ServerUrls.Authentications.children.ServerSocket.children.Create.absoluteUrl);
  }

  public async logoutAsync() {
    this._localStorage.removeItem(API_TOKEN_STORAGE_KEY);

    this._http.deregisterGlobalHeader('Authorization');

    await lastValueFrom(this._store.dispatch(new SetSessionAction(undefined)));

    await this._routeNavigationService.goToDashboardAsync();

    this._window.location.href = `${ this._environment.serverUrl }/${ SERVER_URL_PREFIX }/${ ServerUrls.Authentications.children.Logout.absoluteUrlWithoutLeading }`;
  }
}
