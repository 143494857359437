import { UUID, UUIDBase32 } from '../../../../../common/dto/base.dto';

import * as base32 from 'base32-crockford-browser';

export abstract class UUIDUtils {
  public static convertToBase32(uuid: UUID): UUIDBase32 {
    return base32.encode(uuid);
  }

  public static convertFromBase32(uuidBase32: UUIDBase32): UUID {
    return base32.decode(uuidBase32);
  }
}
