import { RouteNode } from '@coool/route-node';

export const WebsiteUrls = {
  Dashboard: new RouteNode('dashboard', {
    children: {
      DashboardHome: new RouteNode(''),

      Settings: new RouteNode('settings'),

      Game: new RouteNode('game/:gameId', {
        children: {
          GameView: new RouteNode('view'),
          GamePlay: new RouteNode('play'),
        },
        params: {
          gameId: 'gameId',
        },
      }),

      NotFound: new RouteNode('not-found'),
    },
  }),

  Login: new RouteNode('login', {
    children: {
      LoginPage: new RouteNode(''),
      RegistrationPage: new RouteNode('registration'),
      LoginRedirectPage: new RouteNode('redirect'),
    },
  }),

  Terms: new RouteNode('terms'),
  Privacy: new RouteNode('privacy'),

  NotFound: new RouteNode('not-found'),
};
