import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message, MessageType } from './message';
import { filter } from 'rxjs/operators';

@Injectable()
export class MessageBusService {
  private _messageBusSubject = new Subject<Message<any>>();

  public dispatch(message: Message<any>) {
    this._messageBusSubject.next(message);
  }

  public subscribe<T extends MessageType>(messageType: T): Observable<Message<T>> {
    return this._messageBusSubject.asObservable()
      .pipe(
        filter(message => message.type === messageType),
      );
  }

  public subscribeToMultiple(messageTypes: MessageType[]): Observable<Message<any>> {
    return this._messageBusSubject.asObservable()
      .pipe(
        filter(message => messageTypes.includes(message.type)),
      );
  }
}
