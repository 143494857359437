import { DateString, ISODateString } from '../dto/date.dto';

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const HOURS_12_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 12;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const HOUR_IN_MINUTES = 60;
export const DAY_IN_MINUTES = HOUR_IN_MINUTES * 24;

export abstract class DateUtils {
  public static getDaysBetween(a: Date, b: Date): number {
    return Math.round((b.getTime() - a.getTime()) / DAY_IN_MILLISECONDS);
  }

  public static generateDaysBetween(from: Date, to: Date): Date[] {
    const result = [];

    const toDate = this.endOfDay(to);

    let current = this.trimTime(from);

    while (current <= toDate) {
      result.push(current);

      current = this.addDays(current, 1);
    }

    return result;
  }

  public static getMinutesBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / MINUTE_IN_MILLISECONDS);
  }

  public static addDays(date: Date, daysToAdd: number): Date {
    const result = new Date(date);

    result.setDate(result.getDate() + daysToAdd);

    return result;
  }

  public static addMilliseconds(date: Date, milliseconds: number): Date {
    return new Date(date.setMilliseconds(milliseconds));
  }

  public static addMinutes(date: Date, minutes: number) {
    return this.addMilliseconds(date, minutes * MINUTE_IN_MILLISECONDS);
  }

  public static addHours(date: Date, hours: number) {
    return this.addMilliseconds(date, hours * HOUR_IN_MILLISECONDS);
  }

  public static toUNIXTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
  }

  public static toDateStringFromISO(dateString: ISODateString): DateString {
    return <DateString>dateString.substr(0, 10);
  }

  public static toDateString(date: Date): DateString {
    return this.toDateStringFromISO(<ISODateString>date.toISOString());
  }

  public static toTimezonelessISOString(date: Date): string {
    return `${ date.getFullYear() }-${ (date.getMonth() + 1).toString().padStart(2, '0') }-${ date.getDate().toString().padStart(2, '0') }T00:00:00.000Z`;
  }

  public static trimTime(dateWithTime: Date): Date {
    return new Date(Date.UTC(dateWithTime.getUTCFullYear(), dateWithTime.getUTCMonth(), dateWithTime.getUTCDate()));
  }

  public static endOfDayUTC(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      59,
    );
  }

  public static endOfDay(date: Date): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      59,
    );
  }

  public static isSameDay(a: Date, b: Date) {
    return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
  }
}
