import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../services/injection-tokens';
import { Environment } from '../../../../environments/environment.interface';
import { WebsiteUrls } from '../../../../../../common/dto/website-urls';
import { LogoComponent } from '../logo/logo.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LogoComponent,
    RouterLink,
  ],
})
export class FooterComponent implements OnInit {

  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) { }

  public year = new Date().getFullYear();

  public version = this._environment.version;
  public isProduction = this._environment.production;

  public discordUrl = this._environment.links.discordUrl;
  public patreonUrl = this._environment.links.patreonUrl;

  ngOnInit(): void {
  }

  protected readonly RouteLocations = WebsiteUrls;
}
