export enum MessageType {
  PageView,
  ButtonClick,
}

export interface Message<T extends MessageType> {
  type: T;

  payload?: any;
}
