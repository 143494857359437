import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WebsiteUrls } from '../../../../../common/dto/website-urls';
import { RedirectAfterService } from './redirect-after.service';
import { GameId } from '../../../../../common/game/model/game-common.model';
import { UUIDUtils } from '../utils/uuid.utils';

export const NEW_ID = 'new';

@Injectable()
export class RouteNavigationService {
  constructor(
    private _router: Router,
    private _redirectAfterService: RedirectAfterService,
  ) {}

  public async redirectToSavedUrlAsync({ fallback }: { fallback: () => Promise<any> }): Promise<void> {
    const didRedirect = await this._redirectAfterService.redirectToSavedUrlAsync();

    if (!didRedirect) {
      await fallback();
    }
  }

  public async replaceQueryParamsAsync(activatedRoute: ActivatedRoute, queryParams: Params | null) {
    await this._router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      },
    );
  }

  public async goToLoginAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(WebsiteUrls.Login.children.LoginPage.absoluteUrl);
  }

  public async goToRegistrationAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(WebsiteUrls.Login.children.RegistrationPage.absoluteUrl);
  }

  public async goToDashboardAsync() {
    await this._router.navigateByUrl(WebsiteUrls.Dashboard.absoluteUrl);
  }

  public async goToDashboardNotFoundAsync() {
    await this._router.navigateByUrl(WebsiteUrls.Dashboard.children.NotFound.absoluteUrl);
  }

  public async goToGameViewAsync(gameId: GameId) {
    await this._router.navigateByUrl(WebsiteUrls.Dashboard.children.Game.children.GameView.getAbsoluteUrlWithParams({
      gameId: UUIDUtils.convertToBase32(gameId),
    }));
  }

  public async goToGamePlayAsync(gameId: GameId) {
    await this._router.navigateByUrl(WebsiteUrls.Dashboard.children.Game.children.GamePlay.getAbsoluteUrlWithParams({
      gameId: UUIDUtils.convertToBase32(gameId),
    }));
  }
}
