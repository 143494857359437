import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { UserSession } from '../../../common/dto/users.dto';

export interface AppStateModel {
  isInitialized: boolean;

  isUserLoggedIn: boolean;

  session: UserSession | undefined;
}

export class SetIsInitializedAction {
  static readonly type = 'Set Is initialized Action';
}

export class SetSessionAction {
  static readonly type = 'Set Session Action';

  constructor(public session: UserSession | undefined) {
  }
}

const AppStateStateToken = new StateToken<AppStateModel>('AppState');

@State<AppStateModel>({
  name: AppStateStateToken,
  defaults: {
    isInitialized: false,
    isUserLoggedIn: false,
    session: undefined,
  },
})
@Injectable()
export class AppState {
  public static readonly token = AppStateStateToken;

  @Action(SetSessionAction)
  setSession(ctx: StateContext<AppStateModel>, action: SetSessionAction) {
    return ctx.patchState({
      isUserLoggedIn: action.session !== undefined,
      session: action.session,
    });
  }

  @Action(SetIsInitializedAction)
  setInitialized(ctx: StateContext<AppStateModel>) {
    return ctx.patchState({
      isInitialized: true,
    });
  }

  @Selector()
  static isUserLoggedIn(state: AppStateModel) {
    return state.isUserLoggedIn;
  }

  @Selector()
  static session(state: AppStateModel) {
    return state.session;
  }
}
