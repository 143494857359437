import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../services/injection-tokens';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Router } from '@angular/router';
import { LoggerService } from '../services/logger/logger.service';
import { ISODateString } from '../../../../../common/dto/date.dto';

export const REDIRECT_URL_STORAGE_KEY = 'redirect-url';

export interface RedirectData {
  path: string;

  queryParams: { [name: string]: string; };

  date: ISODateString;
}

@Injectable()
export class RedirectAfterService {
  constructor(
    @Inject(WINDOW) private _window: Window,
    private _router: Router,
    private _logger: LoggerService,
    private _localStorage: CoolLocalStorage,
  ) {}

  public saveCurrentUrlForRedirect() {
    const redirectData: RedirectData = {
      path: this._window.location.pathname,
      queryParams: Array.from(new URLSearchParams(this._window.location.search).entries()).reduce((res, item) => {
        res[item[0]] = item[1];
        return res;
      }, <{ [name: string]: string; }>{}),
      date: <ISODateString>new Date().toISOString(),
    };

    this._localStorage.setObject(REDIRECT_URL_STORAGE_KEY, redirectData);
  }

  public async redirectToSavedUrlAsync(): Promise<boolean> {
    const redirectData = this._localStorage.getObject<RedirectData>(REDIRECT_URL_STORAGE_KEY);

    if (!redirectData) {
      return false;
    }

    this._localStorage.removeItem(REDIRECT_URL_STORAGE_KEY);

    try {
      await this._router.navigateByUrl(redirectData.path + '?' + new URLSearchParams(redirectData.queryParams).toString());

      return true;
    } catch (e: any) {
      this._logger.error(e);
    }

    return false;
  }
}
