import { Routes } from '@angular/router';
import { WebsiteUrls } from '../../../common/dto/website-urls';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';

export const routes: Routes = [
  {
    path: WebsiteUrls.Dashboard.relativeUrl,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: WebsiteUrls.Login.relativeUrl,
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: WebsiteUrls.Terms.relativeUrl,
    loadComponent: () => import('./terms/terms.component').then((m) => m.TermsComponent),
  },
  {
    path: WebsiteUrls.Privacy.relativeUrl,
    loadComponent: () => import('./privacy/privacy.component').then((m) => m.PrivacyComponent),
  },
  {
    path: WebsiteUrls.NotFound.relativeUrl,
    loadComponent: () => import('./not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: WebsiteUrls.Dashboard.relativeUrl,
  },
];
