import { ObjectUtils } from '../utils/object.utils';

export enum FeaturePermission {
  Feature_CreateGame = 'feature-create-game',

  Feature_GamePairing = 'feature-game-pairing',
}

export enum FeatureLimitationKey {

}

export interface FeatureLimitations {

}

export interface FeaturePermissions {
  permissions: FeaturePermission[];

  limitations: FeatureLimitations;
}

export function isFeatureEnabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  if (!permissions?.permissions?.length) {
    return false;
  }

  return permissions.permissions.includes(key);
}

export function isFeatureDisabled(key: FeaturePermission, permissions: FeaturePermissions | undefined): boolean {
  return !isFeatureEnabled(key, permissions);
}

export function isAnyFeatureEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return permissionKeys.some((_) => isFeatureEnabled(_, permissions));
}

export function areFeaturesEnabled(
  permissionKeys: FeaturePermission[],
  permissions: FeaturePermissions | undefined,
): boolean {
  return !permissionKeys.some((_) => !isFeatureEnabled(_, permissions));
}

export function mergeFeatureLimitations(base: FeatureLimitations, limitations: Partial<FeatureLimitations>[]): Readonly<FeatureLimitations> {
  const result = <FeatureLimitations>ObjectUtils.deepCopy(base);

  for (const limitation of limitations) {

  }

  return result;
}
