import { MessageType } from '../message-bus/message';

export type TrackingEventCategory = 'User' | 'Misc';

export const TrackingEvents: {
  triggerMessageType: MessageType;
  eventName: string | ((payload: any) => string);
  eventCategory: TrackingEventCategory | ((payload: any) => string);
  passPayload?: boolean;
}[] = [
  {
    triggerMessageType: MessageType.ButtonClick,
    eventName: ({ what, where }: { what: string, where: string }) => `${ what } (${ where })`,
    eventCategory: 'Misc',
    passPayload: true,
  },
];
