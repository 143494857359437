<div class="text-center p-6 md:p-0 md:flex justify-end">
  <div class="mb-6 cursor-pointer text-5xl md:hidden" (click)="closeNav()">&times;</div>

  <div class="my-5 md:mx-5 md:my-0">
    <a class="nav-item"
       [routerLink]="RouteLocations.Dashboard.children.DashboardHome.absoluteUrl"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: true }"
    >
      My Games
    </a>
  </div>

  <div class="md:flex-1 md:text-end">
    <app-user-menu />
  </div>
</div>
