<div id="header" class="text-center">
  <div class="mx-auto w-full max-w-screen-xl px-4">
    <div class="flex-row-center">
      <div [routerLink]="RouteLocations.Dashboard.absoluteUrl" class="cursor-pointer">
        <app-logo height="60px" color="dark"></app-logo>
      </div>

      <div class="flex-1 text-end">
        <div class="hidden md:block md:ms-5">
          <app-header-nav />
        </div>

        <div class="block md:hidden">
          <button class="p-0"
                  (click)="toggleNav()">
            <fa-icon class="inline-block" style="height: 30px" [icon]="faBars" size="2x" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

@if (isNavOpened) {
  <div class="block md:hidden fixed w-full h-full top-0 left-0 bg-white z-50">
    <app-header-nav (onClose)="toggleNav()" />
  </div>
}
