import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { ENVIRONMENT } from '../../../common/services/injection-tokens';
import { Environment } from '../../../../environments/environment.interface';
import { WebsiteUrls } from '../../../../../../common/dto/website-urls';
import { UserSession } from '../../../../../../common/dto/users.dto';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton } from '@angular/material/button';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { RouterLink } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenu,
    FaIconComponent,
    MatMenuTrigger,
    MatMenuItem,
    MatButton,
    RouterLink,
  ],
})
export class UserMenuComponent implements OnInit {

  constructor(
    private _authenticationService: AuthenticationService,
    private _routeNavigationService: RouteNavigationService,
    @Inject(ENVIRONMENT) private _environment: Environment,
    private _changeDetector: ChangeDetectorRef,
  ) { }

  @Select(AppState.session)
  public session$!: Observable<UserSession>;

  public supportEmailAddress = this._environment.links.supportEmail;

  public session: UserSession | undefined;

  public RouteLocations = WebsiteUrls;

  ngOnInit(): void {
    this.session$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(session => {
        this.session = session;

        this._changeDetector.markForCheck();
      });
  }

  public async goToLoginAsync() {
    await this._routeNavigationService.goToLoginAsync();
  }

  public async goToRegistrationAsync() {
    await this._routeNavigationService.goToRegistrationAsync();
  }

  public async logoutClickAsync() {
    await this._authenticationService.logoutAsync();
  }

  protected readonly faCaretDown = faCaretDown;
}
