import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app.state';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { GlobalCacheConfig } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../common/utils/date.utils';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppCommonModule } from './common/common.module';

GlobalCacheConfig.maxCacheCount = 1000;
GlobalCacheConfig.maxAge = 5 * MINUTE_IN_MILLISECONDS;
GlobalCacheConfig.promiseImplementation = Promise;

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),

    BrowserModule,
    HammerModule,

    importProvidersFrom(
      NgxsModule.forRoot([AppState], {
        developmentMode: !environment.production,
      }),
      NgxsSelectSnapshotModule.forRoot(),

      AppCommonModule.forRoot(),
    ),

  ],
};
