import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { UserDTO } from '../../../../../../common/dto/users.dto';

@Injectable()
export class LoggerService {
  constructor(@Inject(ENVIRONMENT) private _environment: Environment) {

  }

  public initialize() {
    if (this._environment.logging?.enabled) {
      Sentry.init({
        dsn: this._environment.logging.dsn,
        autoSessionTracking: true,
      });
    }
  }

  public error(error: Error, data?: any) {
    console.error(error, data);

    if (data) {
      this.captureBreadcrumb('error-data', data);
    }

    if ((<any>error).data) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).data));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    if ((<any>error).body) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).body));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    if (this._environment.logging?.enabled) {
      Sentry.captureException(error);
    }
  }

  public captureBreadcrumb(message: string, data: any = null) {
    if (this._environment.logging?.enabled) {
      Sentry.addBreadcrumb({
        message,
        data,
      });
    }
  }

  private _onUserLoggedIn(user: UserDTO | undefined) {
    Sentry.setContext('user', {
      id: user?.id,
    });
  }
}

