import { Environment } from './environment.interface';
import versionInfo from '../../../version.json';

export const environment: Environment = {
  production: true,

  version: versionInfo.version,

  serverUrl: 'https://api.gomoque.com',
  serverWSUrl: 'https://ws.gomoque.com',

  logging: {
    enabled: true,

    dsn: 'https://5f026a92e6d8490a96593065bef17ce7@o4505509660393472.ingest.sentry.io/4505509662031872',
  },

  tracking: {
    enabled: true,
  },

  links: {
    patreonUrl: 'TODO',
    discordUrl: 'https://discord.gg/nPMS442T',
    supportEmail: 'support@gomoque.com',
  },
};
