@if (session) {
  <div [matMenuTriggerFor]="menu" data-test-id="user-menu" class="cursor-pointer text-dark user-select-none">
    &#64;{{ session.userName }}

    <fa-icon [icon]="faCaretDown" class="ms-1"></fa-icon>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="RouteLocations.Dashboard.children.Settings.absoluteUrl">
      Settings
    </button>
    <button mat-menu-item (click)="logoutClickAsync()">
      Logout
    </button>
  </mat-menu>
} @else {
  <div class="flex flex-col md:flex-row gap-2 justify-end">
    <div>
      <button mat-button color="primary" (click)="goToLoginAsync()" class="bg-transparent">
        <span class="text-dark">Login</span>
      </button>
    </div>
    <div>
      <button mat-stroked-button color="primary" (click)="goToRegistrationAsync()"
              class="sm:ms-3 bg-transparent border-white">
        <span class="text-dark">Register</span>
      </button>
    </div>
  </div>
}
