import { RouteNode } from '@coool/route-node';

export const ServerUrls = {
  Games: new RouteNode('games', {
    children: {
      CreateGameWithAI: new RouteNode(''),

      GameById: new RouteNode(':gameId', {
        children: {
          GetGame: new RouteNode(''),
          Activities: new RouteNode('activities', {
            children: {
              CreateActivity: new RouteNode(''),
            },
          }),
        },
        params: {
          gameId: 'gameId',
        },
      }),
    },
  }),

  Users: new RouteNode('users', {
    children: {
      UserById: new RouteNode(':userId', {
        children: {
          GetUser: new RouteNode(''),

          UserSettings: new RouteNode('settings', {
            children: {
              UserName: new RouteNode('name', {
                children: {
                  Post: new RouteNode(''),
                },
              }),
            },
          }),

          GamePairings: new RouteNode('game-pairings', {
            children: {
              GetPairings: new RouteNode(''),
              CreatePairing: new RouteNode(''),

              GamePairingById: new RouteNode(':gamePairingId', {
                children: {
                  RemovePairing: new RouteNode(''),
                },
                params: {
                  gamePairingId: 'gamePairingId',
                },
              }),
            },
          }),

          UserGames: new RouteNode('games', {
            children: {
              GetUserGames: new RouteNode('', {}),
            },
          }),
        },
        params: {
          userId: 'userId',
        },
      }),
    },
  }),

  Authentications: new RouteNode('authentications', {
    children: {
      Google: new RouteNode('google', {
        children: {
          Get: new RouteNode(''),
          Callback: new RouteNode('callback'),
        },
      }),

      Apple: new RouteNode('apple', {
        children: {
          Get: new RouteNode(''),
          Callback: new RouteNode('callback'),
        },
      }),

      Session: new RouteNode('session', {
        children: {
          CreateNew: new RouteNode(''),
        },
      }),

      ServerSocket: new RouteNode('server-socket', {
        children: {
          Create: new RouteNode(''),
        },
      }),

      Logout: new RouteNode('logout'),
    },
  }),

  Health: new RouteNode('health', {
    children: {
      Check: new RouteNode(''),
      HTMLCheck: new RouteNode('html'),
      Info: new RouteNode('info'),
    },
  }),
};

export const SERVER_URL_PREFIX = 'api';