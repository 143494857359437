<div class="app-container">
  <div class="header-container">
    <app-header />
  </div>
  <div class="main-container">
    <router-outlet />
  </div>
  <div class="footer-container">
    <app-footer />
  </div>
</div>
