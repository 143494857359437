import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from './injection-tokens';
import { io, Socket } from 'socket.io-client';
import {
  WEBSOCKET_MESSAGE,
  WebsocketMessageDTO,
  WebsocketMessageType,
  WSAuthConnectRequestDTO,
} from '../../../../../common/dto/websocket.dto';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppState } from '../../app.state';
import { Store } from '@ngxs/store';
import { AuthenticationService } from './authentication.service';
import { ServerSocketAuthToken } from '../../../../../common/dto/auth.dto';

@UntilDestroy()
@Injectable()
export class ServerSocketService {
  private _socket?: Socket;
  private _socketMessages = new Subject<WebsocketMessageDTO<any>>();

  constructor(
    private _store: Store,
    @Inject(ENVIRONMENT) private _environment: Environment,
    private _authenticationService: AuthenticationService,
  ) {
  }

  public appState = this._store.select(AppState.token);

  public initialize() {
    this.appState
      .pipe(
        filter(_ => _.isInitialized),
        untilDestroyed(this),
      )
      .subscribe(async state => {
        if (state.isUserLoggedIn) {
          if (state.session && !this._socket) {
            const sessionToken = await this._authenticationService.getServerSocketAuthTokenAsync();

            if (sessionToken) {
              this.tryConnect(sessionToken.token);
            }
          }
        } else {
          this.tryDisconnect();
        }
      });
  }

  private tryConnect(authToken: ServerSocketAuthToken) {
    this._socket = io(this._environment.serverWSUrl);

    this._socket.on(WEBSOCKET_MESSAGE, data => {
      this._socketMessages.next(data);
    });

    this._socket.on('connect', () => {
      this._sendMessage<WSAuthConnectRequestDTO>({
        type: WebsocketMessageType.AuthConnectRequest,
        data: {
          token: authToken,
        },
      });
    });
  }

  public tryDisconnect() {
    this._socket?.disconnect?.();
  }

  public getMessagesOfType$<T extends WebsocketMessageDTO<any>>(messageType: WebsocketMessageType): Observable<T> {
    return this._socketMessages
      .pipe(
        filter(_ => _.type === messageType),
        untilDestroyed(this),
      ) as Observable<T>;
  }

  private _sendMessage<T extends WebsocketMessageDTO<any>>(message: T): boolean {
    if (!this._socket || !this._socket.connected) {
      return false;
    }

    this._socket.emit(WEBSOCKET_MESSAGE, message);

    return true;
  }
}
