import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WebsiteUrls } from '../../../../../common/dto/website-urls';
import { RouterLink } from '@angular/router';
import { LogoComponent } from '../../common/components/logo/logo.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    LogoComponent,
    FaIconComponent,
    MatButtonModule,
    HeaderNavComponent,
  ],
})
export class HeaderComponent {
  public isNavOpened = false;

  public RouteLocations = WebsiteUrls;

  public toggleNav() {
    this.isNavOpened = !this.isNavOpened;
  }

  protected readonly faBars = faBars;
}
