import { UserId, UserSession } from './users.dto';
import { DistinctId } from './base.dto';

export type AuthToken = DistinctId<string, 'AuthToken'>;

export type ServerSocketAuthToken = DistinctId<string, 'ServerSocketAuthToken'>;

export interface ServerSocketAuthTokenPayload {
  userId: UserId;
}

export interface ServerSocketAuthTokenDTO {
  token: ServerSocketAuthToken;
}

export interface AuthTokenPayload {
  session: UserSession;
}

export enum AuthenticationType {
  Google = 'google',

  Apple = 'apple',
}

export interface AuthenticationTokenPayload {
  session: UserSession;
}