import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { WebsiteUrls } from '../../../../../../common/dto/website-urls';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UserMenuComponent,
    RouterLink,
    RouterLinkActive,
  ],
})
export class HeaderNavComponent {
  @Output()
  public onClose = new EventEmitter<void>();

  public closeNav() {
    this.onClose.emit();
  }

  protected readonly RouteLocations = WebsiteUrls;
}
