import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from './common/services/injection-tokens';
import { FooterComponent } from './common/components/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FooterComponent,
    RouterOutlet,
    HeaderComponent,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    @Inject(WINDOW) private _window: Window | null,
  ) {

  }

  public ngOnInit(): void {
    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._window?.scrollTo?.(<any>{ top: 0, left: 0, behavior: 'instant' });
      });
  }
}
